import {isEventsInMembersInstalled} from '../../../../../../../../commons/selectors/installed-apps'
import {isMembersVisible} from '../../../../../selectors/settings'
import {AppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/connect'
import {OverlayOwnProps, OverlayStateProps} from './interfaces'
import {Overlay as Presentation} from './overlay'

const mapRuntime = ({state}: AppProps, {event}: OverlayOwnProps): OverlayStateProps => ({
  membersEnabled: isEventsInMembersInstalled(state),
  showMembers: isMembersVisible(state, event),
})

export const Overlay = connect<OverlayOwnProps, OverlayStateProps>(mapRuntime)(Presentation)
