import {getRibbonData} from '../../../selectors/events'
import {isItemOpened} from '../../../selectors/layout'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {Card as Presentation} from './card'
import {CardOwnProps, CardStateProps} from './interfaces'

const mapRuntime = ({state}: AppProps, {event}: CardOwnProps): CardStateProps => ({
  hasRibbon: getRibbonData(state, event).visible,
  itemOpened: isItemOpened(state, event.id),
})

export const Card = connect<CardOwnProps, CardStateProps>(mapRuntime)(Presentation)
