import {focusElement, hookToAttributeSelector} from '@wix/panda-js-utils'
import {IMAGE_HOVER_EFFECT} from '@wix/wix-events-commons-statics'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React, {KeyboardEventHandler, useEffect, useMemo, useState} from 'react'
import {usePrevious} from '../../../../../../commons/hooks/use-previous'
import {useImageHoverEffect} from '../../../hooks/use-image-hover-effect'
import {useVisibilityStyles} from '../../../hooks/use-visibility-styles'
import {EventImage} from '../../event-image'
import s from './card.scss'
import {Content} from './components/content'
import {Overlay} from './components/overlay'
import {CardProps, CardWrapperProps} from './interfaces'

const getImageHoverEffectClass = (imageHoverEffect: IMAGE_HOVER_EFFECT) => {
  if (imageHoverEffect === IMAGE_HOVER_EFFECT.ZOOM_IN) {
    return s.zoomInEffect
  }

  return null
}

export const Card = ({event, hasRibbon, itemOpened, listItem}: CardProps) => {
  const [expanded, setExpanded] = useState(false)
  const prevExpanded = usePrevious(expanded)
  const {isEditor} = useEnvironment()

  const {
    allBreakpoints: {isListImageEnabled},
    currentBreakpoint: {isListAdditionalComponentsVisible},
  } = useVisibilityStyles()

  const {effectPreviewActive, imageHoverEffect} = useImageHoverEffect()
  const imageHoverEffectClassName = useMemo(() => getImageHoverEffectClass(imageHoverEffect), [imageHoverEffect])

  const showOverlay = expanded || (isEditor && itemOpened && isListAdditionalComponentsVisible())

  const toggleOverlay = () => setExpanded(prev => !prev)

  useEffect(() => {
    if (prevExpanded && !expanded) {
      focusElement({selector: hookToAttributeSelector(DH.moreInfoLink(event.id)), canFocus: true})
    }
  }, [expanded])

  const handleEscPress: KeyboardEventHandler = e => {
    if (e.key === 'Escape' && expanded) {
      toggleOverlay()
    }
  }

  return (
    <CardWrapper
      onEscPress={handleEscPress}
      listItem={listItem}
      className={effectPreviewActive ? s.forceImageHoverEffect : null}
    >
      <div className={classNames(s.container, {[s.hideElements]: showOverlay})}>
        {isListImageEnabled() && (
          <div className={s.imageContainer}>
            <EventImage
              event={event}
              opacityFallback
              backgroundFallback
              className={classNames(s.image, imageHoverEffectClassName)}
            />
          </div>
        )}
        <Content event={event} toggleOverlay={toggleOverlay} hasRibbon={hasRibbon} />
      </div>
      {showOverlay && <Overlay event={event} toggleOverlay={toggleOverlay} hasRibbon={hasRibbon} />}
    </CardWrapper>
  )
}

const CardWrapper = ({listItem, onEscPress, children, className}: CardWrapperProps) => {
  const props = {
    onKeyDown: onEscPress,
    className: classNames(s.root, className),
    'data-hook': DH.card,
  }

  return listItem ? <li {...props}>{children}</li> : <div {...props}>{children}</div>
}
