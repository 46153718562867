import {useStyles} from '@wix/tpa-settings/react'
import {useDidUpdate} from '@wix/wix-events-commons-statics'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import {useState} from 'react'
import stylesParams from '../../stylesParams'

export const useImageHoverEffect = () => {
  const {isEditor} = useEnvironment()
  const [effectPreviewActive, setEffectPreviewActive] = useState(false)

  const {get} = useStyles()
  const imageHoverEffect = get(stylesParams.listImageHoverEffect)

  useDidUpdate(() => {
    if (!isEditor) {
      return
    }

    setEffectPreviewActive(true)

    const timeoutId = setTimeout(() => {
      setEffectPreviewActive(false)
    }, 1000)

    return () => {
      setEffectPreviewActive(false)
      clearTimeout(timeoutId)
    }
  }, [imageHoverEffect])

  return {effectPreviewActive, imageHoverEffect}
}
