import React from 'react'
import {useWidgetState} from '../../../hooks/state-provider'
import {getEvents} from '../../../selectors/events'
import {Card} from '../card'

export const FeaturedCard = () => {
  const [featuredEvent] = useWidgetState(getEvents)

  return <Card event={featuredEvent} />
}
