import {useStyles} from '@wix/tpa-settings/react'
import {WIDGET_TYPE} from '@wix/wix-events-commons-statics'
import React from 'react'
import stylesParams from '../../../stylesParams'
import {CardList} from './card-list'
import {FeaturedCard} from './featured-card'

export const Cards = () => {
  const {get} = useStyles()
  const featuredWidget = get(stylesParams.widgetType) === WIDGET_TYPE.FEATURED

  return featuredWidget ? <FeaturedCard /> : <CardList />
}
