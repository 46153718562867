import {useSettings} from '@wix/tpa-settings/react'
import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {TextButton} from 'wix-ui-tpa/cssVars'
import settingsParams from '../../../../../../settingsParams'
import {useVisibilityStyles} from '../../../../../hooks/use-visibility-styles'
import {shouldShowCountdown} from '../../../../../selectors/layout'
import classesV2 from '../../../../classes-v2.scss'
import {Countdown} from '../../../../countdown'
import {FullDateLocation} from '../../../../full-date-location'
import {LinkToPage} from '../../../../link-to-page'
import {Ribbon} from '../../../../ribbon'
import {RsvpButton} from '../../../../rsvp-button'
import {ShortDateLocation} from '../../../../short-date-location'
import s from './content.scss'
import {classes} from './content.st.css'
import {ContentProps} from './interfaces'

export const Content = ({event, toggleOverlay, hasRibbon}: ContentProps) => {
  const {
    allBreakpoints: {
      isListAdditionalComponentsVisible,
      isListCountdownEnabled,
      isListFullDateLocationVisible,
      isListShortDateLocationVisible,
    },
  } = useVisibilityStyles()
  const {get} = useSettings()
  const moreInfoText = get(settingsParams.moreInfo) as string
  const showCountdown = shouldShowCountdown(event)

  const {experiments} = useExperiments()
  const widgetHeightSettingsEnabled = experiments.enabled(ExperimentNames.WidgetHeightSettings)

  return (
    <div className={s.container}>
      <div className={widgetHeightSettingsEnabled ? s.details : null}>
        {hasRibbon ? (
          <Ribbon event={event} className={classNames(s.ribbon, {[s.overrideMargin]: !showCountdown})} />
        ) : null}
        {isListCountdownEnabled() && showCountdown && (
          <div className={s.countdown}>
            <Countdown event={event} />
          </div>
        )}
        <LinkToPage allowExternal event={event} dataHook="title" className={s.title}>
          {event.title}
        </LinkToPage>
        {isListShortDateLocationVisible() && (
          <div className={s.shortDateLocation}>
            <ShortDateLocation
              event={event}
              dateClassName={classesV2.eventDate}
              locationClassName={classesV2.eventLocation}
            />
          </div>
        )}
        {isListFullDateLocationVisible() && (
          <div className={s.fullDateLocation}>
            <FullDateLocation
              event={event}
              dateClassName={classesV2.eventDate}
              locationClassName={classesV2.eventLocation}
            />
          </div>
        )}
        {isListAdditionalComponentsVisible() && (
          <div className={s.moreInfoWrapper}>
            <TextButton className={classes.moreInfo} onClick={toggleOverlay} data-hook={DH.moreInfoLink(event.id)}>
              {moreInfoText}
            </TextButton>
          </div>
        )}
      </div>
      <RsvpButton event={event} />
    </div>
  )
}
